import React, { Component } from "react";
import Footer from "./Footer";
 
class Home extends Component {
    render() {
        return (
        <div class="container" id='frontdoor'>
            <div class="jumbotron">
                {/* <h1 class="display-4">Chris Hwang </h1>  */}
                <h1 class="disply-4">Quantitative Investment Research Platform</h1>
                <p class="lead">This website is a platform that includes several demo apps for quantitative investment research, built by Chris IJ Hwang [<a href="http://www.linkedin.com/in/chrisijh" target="_blank">
      <i class="fa fa-linkedin"></i></a>]. It features three main sections: applications for <b><u>Equity Long/Short Portfolio</u></b> management, 
      predictive analytics using traditional <em>ARIMA</em> and the latest <b><u>Time Series Foundation Models</u></b>, 
      and a <b><u>private RAG LLM</u></b> with financial analysis reports. <b><u>Bayesian Inference</u></b> is used throughout the applications to enhance predictive modeling and decision-making.
       The platform is designed to be <b><u>a fully private system within a secure network</u></b>. But, it is accessible via the internet solely for demonstration purposes.

                </p>
                
               {/* <p class="lead">I am a Quantitative Analyst/Developer and Data Scientist with experience in Finance, IT, and Education industry. 
                This site contains some of the demo apps that I have worked on. 
                Some other works can be found at my github page <a href="http://chrisijh.github.io" target="_blank">here.</a> </p> */}

                
                <hr class="my-4"/>
                {/* <p>If you have any question, feel free to contact me at ih138 at columbia dot edu</p> */}
                {/* <a class="btn btn-primary btn-lg" href="#" role="button">Learn more</a> */}
            </div>

            <div >
                <h1>Platform Architecture</h1>
                    <p>The platform is built on top of the following technologies:</p>
                    <img src="images/diagram-export-2-13-2025-6_20_39-PM.svg" alt="Platform" style={{width: "100%", height:  "auto" }}/>
                <h1>Universe</h1>
                    
                    <li>500+ tickers in U.S. Stock Market</li>
                        <ul><strong>Data Sources</strong>
                            <li>Yahoo Finance</li>
                            <li>EOD historical data</li>
                        </ul>

                    <ul><strong>Factors</strong>
                    <li>Time Series Factors</li>
                        <ul>
                        <li>Fama-French Factors</li>
                            <ul>
                                <li>SMB (Small Minus Big)</li>
                                <li>HML (High Minus Low)</li>
                                <li>Rm-Rf (The excess return on the market)</li>
                                <li>RMW (Robust Minus Weak)</li>
                                <li>CMA (Conservative Minus Aggressive)</li>
                            </ul>
                        <li>Russell indexes</li>
                            <ul>
                                <li>Russell 1000 value: Mid Cap, Value (RLV)</li>
                                <li>Russell 1000 growth: Mid Cap, Growth (RLG)</li>
                                <li>Russell 2000 value: Small Cap, Value (RUT)</li>
                                <li>Russell 2000 growth: Small Cap, Growth (RUO)</li>
                            </ul>
                        </ul>
                    <li>Cross-Sectional Factors</li>
                        <ul>
                        <li>Custom Fundamental Factors(to be added)</li>
                        </ul>
                    </ul>
                
                <br></br>
                <hr></hr>
                <h1>Content</h1>
                <br></br>
                    <hr></hr>
                    <h2>Market Summary</h2>
                    <br></br>
                    <div className="card mb-3">
                        <div className="card-body">
                            <h4 className="card-title">Sector Rotation</h4>
                            <a href="http://cedge.us/#/SectorRotationDaily" target="_blank" className="btn btn-primary mb-2">Link</a>
                            <p className="card-text">Information of Sector performance per</p>
                            <ul className="card-text">
                                <li>GicSector</li>
                                <li>GicGroup</li>
                                <li>GicIndustry</li>
                                <li>GicSubIndustry</li>
                            </ul>
                            <p className="card-text"><b>and per Equal-Weight vs. Market-Cap-Weight</b></p>
                        </div>
                    </div>
                    <hr></hr>
                    <h2>Portfolio Analysis</h2>
                    <br></br>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4 className="card-title">Portfolio Status</h4>
                                <a href="http://cedge.us/#/portfolioform1analysis" target="_blank" className="btn btn-primary mb-2">Link</a>
                                <p className="card-text">Portfolio status displaying the current factor/sector exposures and the past performances.</p>
                            </div>
                        </div>
                            
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4 className="card-title">Portfolio Optimization</h4>
                                <a href="http://cedge.us/#/portfolioform1" target="_blank" className="btn btn-primary mb-2">Link</a>
                                <p className="card-text">Once the current status of the portfoilo is displayed, the portfolio will be optimized in the optimization page.
                                    Currently Factor Neutral/Bias optimizaion is available.</p>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4 className="card-title">Style Attribution Analysis</h4>
                                <a href="http://cedge.us/#/portfoliostyleattribution" target="_blank" className="btn btn-primary mb-2">Link</a>
                                    <p>Identify the styles that can be associated with the major risk factors in a specific mutual fund.</p>
                                    <p>Curently Available Mutual Fund are :</p>
                                    <p>ABALX, AGTHX, FXAIX, VBTLX, VFFSX, VFIAX, VGTSX, VIIIX, VINIX, VMFXX, VSMPX, VTBIX, VTBNX, VTSAX</p>
                            </div>
                        </div>
                    <hr></hr> 
                    <h2>Predictive Analytics</h2>
                    <br></br>
                    <div className="card mb-3">
                        <div className="card-body">   
                                    <h4>Performance Analysis using Bayesian Inference</h4><a href="http://cedge.us/#/modelsComp" target="_blank" className="btn btn-primary mb-2">Link</a>
                                    {/* <p>With various parameters per model, collect historic hit ratio. Based on Beta distribution, 
                                        compute the probability of the hit ratio is over certain probability.
                                    </p> */}
                                    <p>Compare the confidence of the prediction model of difference probability per sectors and different parameters by aggregating all performances available.</p>
                                    <p>Methodology</p> 
                                    <ul>
                                        <li>Bayesian Inference</li>
                                        <li>Based on hit ratio</li>
                                        <li>Likelihood: Past hit ratio as Beta Distribution</li>
                                        <li>Prior: Non-informative</li>
                                        <li>Performance explained as: "Based on Posterior Distribution, there is a better than the probability of [ ] that the chance of the correct prediction is greater than the probability of [ ]"</li>
                                    </ul>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">  
                                <h4>Foundation Model Time Series Prediction</h4><a href="http://cedge.us/#/FoundationTS" target="_blank" className="btn btn-primary mb-2">Link</a>
                                    <p>Compare the performance of the latest foundation model time series prediction with zero-shot  
                                        and fine-tuned results.</p>
                                    <ul>
                                        <li>Lag-Llama</li>
                                        {/* <li>Long Short-Term Memory Networks(LSTM)</li> */}
                                    </ul>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">   
                                <h4 className="card-title">Predictions</h4>
                                <a href="http://cedge.us/#/tickerModels" target="_blank" className="btn btn-primary mb-2">Link</a>
                                <p className="card-text">Display the latest prediction per model</p>
                                <ul>
                                    <li>ARIMA</li>
                                </ul>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">   
                                <h4 className="card-title">Return Analysis</h4>
                                <a href="http://cedge.us/#/tickerModels2" target="_blank" className="btn btn-primary mb-2">Link</a>
                                <p className="card-text">Past Return Analysis</p>
                                <ul>
                                    <li>Compare daily return fitted Student-T distribution and Normal distribution up to three different periods 
                                        using Maximum Likelihood Estimation</li>
                                </ul>
                                <p className="card-text">Ex-ante Return Analysis</p>
                                <ul>
                                    <li>Display the posterior return distribution with different parameters</li>
                                    <li>Likelihood: Past daily return</li>
                                    <li>Prior: Beta Distribution with different parameters</li>
                                </ul>
                                <p className="card-text">Factor Analysis: Factor Loadings</p>
                                <ul>
                                    <li>SMB</li>
                                    <li>RMW</li>
                                    <li>HML</li>
                                </ul>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <div className="card-body">   
                                <h4 className="card-title">Backtesting</h4>
                                <a href="http://cedge.us/#/tickerBacktesting" target="_blank" className="btn btn-primary mb-2">Link</a>
                                <p className="card-text">Display the performance of the backtesting per model</p>
                            </div>
                        </div>
                    <hr></hr>
                    {/* <h2>Research</h2>
                    <br></br>
                        <p>Deep Fundamental Factor Models - Matthew F. Dixon, Nicholas G. Polson : <span><a href="http://cedge.us/#/dlfm" target="_blank">Link</a></span></p> 
                        
                        <p>Building Diversified Portfolios that Outperform Out-of-Sample - Marcos Lopez de Prado : <a href="http://cedge.us/#/hcaform1" target="_blank">Link</a></p>
                         */}

            </div> 
            <hr></hr>
            <Footer />
            {/* <footer class="mastfoot mt-auto">
                            <div class="inner">
                                <p>© 2023 Copyright Chris IJ Hwang</p>
                            </div>
                            <p>DISCLAIMER: The information in this site is for the purpose of research and backtest only. We don't promise any future profits and don't take responsibility for any trading losses.
                            </p>
            </footer> */}
        </div>
        );
    }


}

export default Home;